function dynamicSetFontSize() {
    var count = 5
    dynamicSet()
    var timer = setInterval(function () {
        if (!count) clearInterval(timer)
        dynamicSet()
        count--
    }, 200)
    window.removeEventListener('resize', dynamicSet)
    window.addEventListener('resize', dynamicSet)
}

function dynamicSet() {
    var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth || 0,
        fontSize = width * 20 / 375
    fontSize > 25 && (fontSize = 25)
    if (dynamicSet.cacheWidth === undefined || dynamicSet.cacheWidth !== width) {
        dynamicSet.cacheWidth = width
        document.documentElement.style.fontSize = fontSize + 'px'
    }
}

export {
    dynamicSetFontSize
}
