import React, { Component } from 'react';
import './index.less'
import IndexHome from '../index-home/indexHome'
class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      init: false
    }
  }
  render() {
    return (
      <div className="home-page">
        <IndexHome></IndexHome>
      </div>
    );
  }
}

export default Home