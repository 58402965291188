import React from 'react';
import ReactDOM from 'react-dom';
// import { HashRouter } from 'react-router-dom';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import {dynamicSetFontSize} from './components/index-home/static/dynamicSetFontSize'

dynamicSetFontSize()
ReactDOM.render(<App />, document.getElementById('root'));
const renderApp = () => {
    ReactDOM.render(
        <App />,
        document.getElementById('root')
    );
};
renderApp();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
