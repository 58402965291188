import React from "react";
import "./indexHome.less";

const IndexHome = (props) => {
  return (
    <div className="index-home-container">
      <span
        className="top-icon"
        onClick={() => {
          window.location.href = "https://www.didiglobal.com";
        }}
      ></span>
      <div className="first-head">
        <div className="cir-mask"></div>
        <div className="tagline">
          <p>MORE THAN A</p>
          <p>JOURNEY</p>
          <p>滴滴一下 美好出行</p>
          <div
            onClick={() => {
              window.location.href = "https://www.didiglobal.com";
            }}
          >
            前往滴滴升级官网
          </div>
        </div>
        <div
          className="top-hint"
          onClick={() => {
            window.location.href = "https://www.didiglobal.com";
          }}
        >
          <p>前往滴滴升级官网</p>
          <p>滴滴官网已升级，请访问www.didiglobal.com了解更多业务信息。</p>
        </div>
      </div>
      <div
        className="first-enterprise-item"
        onClick={() => {
          window.location.href = "https://es.xiaojukeji.com/";
        }}
      >
        <div className="hint">
          <p>企业用车 不再繁琐</p>
        </div>
      </div>
      <div
        className="first-fast-ride-item"
        onClick={() => {
          window.location.href =
            "https://www.didiglobal.com/travel-service/express";
        }}
      >
        <div className="hint">
          <p>滴滴快车 省时间给生活</p>
        </div>
      </div>
      <div
        className="first-luxury-item"
        onClick={() => {
          window.location.href =
            "https://www.didiglobal.com/travel-service/luxe";
        }}
      >
        <div className="hint">
          <p>滴滴豪华车 新奢享主义出行</p>
        </div>
      </div>
      <div
        className="first-tailored-item"
        onClick={() => {
          window.location.href =
            "https://www.didiglobal.com/travel-service/premier";
        }}
      >
        <div className="hint">
          <p>礼橙专车 礼遇每一程</p>
        </div>
      </div>
      <div className="index-home-bottom">
        <div className="index-home-desc">
          <p>客服热线</p>
          <p>司机客服热线 +86 400 0000 666</p>
          <p>乘客客服热线 +86 400 0000 999</p>
          <p>金融客服热线 +86 400 6080 966</p>
          <p>代驾客服热线 +86 400 7666 998</p>
          <p>其他业务热线 +86 400 0000 999-9</p>
          <p>
            <span
              onClick={() => {
                window.location.href =
                  "https://www.didiglobal.com/contact/contact-us";
              }}
            >
              联系我们
            </span>
            <span
              onClick={() => {
                window.location.href =
                  "https://www.didiglobal.com/contact/recruit";
              }}
            >
              司机加盟
            </span>
            <span
              onClick={() => {
                window.location.href = "https://www.didiglobal.com";
              }}
            >
              滴滴官网
            </span>
            <span
              onClick={() => {
                window.location.href = "https://talent.didiglobal.com";
              }}
            >
              滴滴招聘
            </span>
          </p>
          <div className="index-home-footer">
            <div className="icon"></div>
            <div className="desc">
              <p>
                <span
                  onClick={() => {
                    window.location.href =
                      "https://dpubstatic.udache.com/static/dpubimg/6a1ccba594e1da66244e41afd6bc1e3f/index.html#1";
                  }}
                >
                  &copy;2012-{new Date().getFullYear()} 滴滴出行科技有限公司
                </span>{" "}
                <span
                  onClick={() => {
                    window.location.href =
                      "https://dpubstatic.udache.com/static/dpubimg/6a1ccba594e1da66244e41afd6bc1e3f/index.html#2";
                  }}
                >
                  增值电信业务经营许可证：津B2-20210022
                </span>
                <span
                  onClick={() => {
                    window.location.href =
                      "https://dpubstatic.udache.com/static/dpubimg/6a1ccba594e1da66244e41afd6bc1e3f/index.html#3";
                  }}
                >
                  B2-20160325
                </span>
                {/* <br /> */}
                <span className="second-line">
                  <span
                    onClick={() => {
                      window.location.href =
                        "http://wyc.tjkg.cn/system/index.action";
                    }}
                  >
                    网络预约出租汽车经营许可证：
                  </span>
                  <span
                    onClick={() => {
                      window.location.href =
                        "https://dpubstatic.udache.com/static/dpubimg/6a1ccba594e1da66244e41afd6bc1e3f/index.html#4";
                    }}
                  >
                    津交运管许可出网字100001号
                  </span>{" "}
                  <span
                    onClick={() => {
                      window.location.href = "https://beian.miit.gov.cn";
                    }}
                  >
                    津ICP备15007503号-1
                  </span>{" "}
                  <span
                    onClick={() => {
                      window.location.href =
                        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12010402001586";
                    }}
                  >
                    <img src={require("./imgs/police.png")} alt="police" />
                    津公网安备 12010402001586号
                  </span>
                </span>
                {/* <br /> */}
                <span className="report-line">
                  违法和不良信息/涉未成年有害信息/算法安全专项举报电话：01083456200，举报邮箱：xinxichuli@didiglobal.com
                  <a
                    href="https://www.12377.cn"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    网上有害信息举报专区
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexHome;
